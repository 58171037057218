var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"about"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-around align-items-center"},[_c('div',{staticClass:"col-12 col-md-8 profile"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-3 img-area"},[_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"avatar-mask",class:{ play: _vm.isLoaded, loading: !_vm.isLoaded }}),_c('img',{attrs:{"src":require("@/assets/img/profile.jpg")},on:{"load":function($event){_vm.isLoaded = true}}})])]),_c('div',{staticClass:"col-12 col-lg-9 mt-3"},[_c('h1',{staticClass:"text-center text-lg-left name item",style:(_vm.getSlideStyle(0))},[_vm._v("黃省喬")]),_c('h6',{staticClass:"mb-2 text-muted text-center text-lg-left item",style:(_vm.getSlideStyle(1)),on:{"click":_vm.nextPronunciation}},[_vm._v(_vm._s(_vm.pronunciation))]),_c('p',{staticClass:"text-center text-lg-left item",style:(_vm.getSlideStyle(2))},_vm._l((_vm.infos),function(ref){
var title = ref.title;
var icon = ref.icon;
var name = ref.name;
return _c('small',{staticClass:"mr-3",attrs:{"title":(title + ": " + name)}},[_c('fa',{attrs:{"icon":icon}}),_vm._v(" "+_vm._s(name))],1)}),0),_c('hr'),_c('p',{staticClass:"item description",style:(_vm.getSlideStyle(3))},[_vm._v(_vm._s(_vm.greeting)),_c('br'),_vm._v(_vm._s(_vm.description))]),_c('p',{staticClass:"item description",style:(_vm.getSlideStyle(4))},[_c('fa',{staticClass:"mx-1",attrs:{"icon":['fas', 'arrow-circle-right']}}),_c('a',{attrs:{"href":_vm.links.cakeResume.url,"target":"_blank"}},[_vm._v("CakeResume")]),_c('span',{staticClass:"mr-1"}),_c('fa',{staticClass:"mx-1",attrs:{"icon":['fas', 'arrow-circle-right']}}),_c('a',{attrs:{"href":_vm.links.googleDocsResume.url,"target":"_blank"}},[_vm._v("English Resume")])],1),_c('p',{staticClass:"mb-4"},_vm._l((_vm.hashtags),function(hashtag,index){return _c('span',{staticClass:"popup hashtag",style:(_vm.getPopupStyle(index))},[_c('span',[_vm._v(_vm._s(hashtag))])])}),0),_c('p',{staticClass:"text-center text-lg-left item",style:(_vm.getSlideStyle(5))},_vm._l((_vm.socials),function(ref,index){
var title = ref.title;
var icon = ref.icon;
var url = ref.url;
return _c('a',{staticClass:"popup btn-social mr-2",style:(_vm.getPopupStyle(index + _vm.hashtags.length)),attrs:{"href":url,"target":"_blank","title":title}},[(icon)?_c('fa',{staticClass:"ml-1",attrs:{"icon":icon}}):_vm._e()],1)}),0)])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }